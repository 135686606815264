/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var UTIL;

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        UTIL.fire( 'common', 'wowEffects' );
        UTIL.fire( 'common', 'menu' );
        UTIL.fire( 'common', 'agendaBlock' );
        UTIL.fire( 'common', 'randosModals' );
        UTIL.fire( 'common', 'carousels' );

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
      wowEffects: function() {

        var wow = new WOW(
          {
            boxClass:     'wow',      // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            offset:       0,          // distance to the element when triggering the animation (default is 0)
            mobile:       true,       // trigger animations on mobile devices (default is true)
            live:         true,       // act on asynchronously loaded content (default is true)
          }
        );
        wow.init();

        // CountUp
        var options = {
          useEasing: true,
          useGrouping: true,
          separator: ' ',
          decimal: '.',
        };
        $('.countup[data-count-start][data-count-end]').each(function(){
          var decimals = $(this).data('count-decimals') ? parseInt( $(this).data('count-decimals') ) : 0;
          var duration = $(this).data('count-duration') ? parseInt( $(this).data('count-duration') ) : 3;
          var count = new CountUp($(this).get(0), parseInt( $(this).data('count-start') ), parseInt( $(this).data('count-end') ), decimals, duration, options);
          if (!count.error) {
            var watcher = inViewport($(this).get(0), { debounce: 10 }, function() {
              count.start();
              watcher.dispose();
            });
          }

        });

      },
      menu: function() {
        var toggleMenu = UTIL.debounce( function ( $el, target ) {
          console.log(target);
          if ( target ) {
            var $target = $(target);
            $el.toggleClass('is-active');
            $target.toggleClass('is-active');
            if ( $target.hasClass('is-active') ) {
              $('html').addClass('menu-open');
            }
            else {
              $('html').removeClass('menu-open');
            }
          }
        }, 100 );
        var $navbarBurgers = $('.navbar-burger');
        $navbarBurgers.each(function () {
          var $el = $(this);
          $el.on('touchstart', function (e) {
            e.preventDefault();
            e.stopPropagation();
            toggleMenu( $(this), $el.data('target') );
          }).on('click', function (e) {
            e.preventDefault();
            toggleMenu( $(this), $el.data('target') );
          });
        });

        var windowScrollTop = 0,
            scrollTopFix = UTIL.debounce(function() {
              windowScrollTop = $(window).scrollTop();
              if ( windowScrollTop > 10 ) {
                $('html').addClass('scrolled');
              }
              else {
                $('html').removeClass('scrolled');
              }
            }, 100);
        scrollTopFix();
        $(window).on('scroll', scrollTopFix);
      },
      carousels: function() {

        // FOOTERS
        $('.owl-carousel.partenaires').owlCarousel({
          loop: true,
          margin: 10,
          navText: [ '<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>' ],
          responsiveClass: true,
          nav: true,
          responsive: {
            0: { items: 1 },
            600: { items: 2 },
            800: { items: 3 },
            1200: { items: 5 }
          }
        });
        // Randos side
        $('.owl-carousel.randos').owlCarousel({
          loop: true,
          margin: 10,
          navText: [ '<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>' ],
          responsiveClass: true,
          nav: true,
          items: 1,
        });
        if ( $(window).width() < 768 ) {
          // Randos menu
          $('.randos-list-menu .main').addClass('owl-carousel').owlCarousel({
            loop: true,
            margin: 10,
            navText: [ '<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>' ],
            responsiveClass: true,
            nav: true,
            items: 1,
          });
        }

      },
      randosNiveauxTooltip: function() {
        $.each( [ 'vert', 'bleu', 'orange', 'noir' ], function(key, niveau) {
          if ( typeof randosSettings != 'undefined' && typeof randosSettings.niveaux[niveau] != 'undefined' ) {
            $('.niveau-tooltip[data-niveau='+niveau+']').each(function(){
              new Tooltip( $(this).get()[0], {
                placement: "top",
                container: $(this).parents('.rando').get()[0],
                html: true,
                // delay: 300,
                title: randosSettings.niveaux[niveau],
              });
            });
          }
        });
      },
      randosModals: function() {
        // var $randoModal = $('.rando-modal');
        $(document).on('click', '.rando-modal-link', function (e) {
          e.preventDefault();
          $('#rando-modal-'+$(this).data('rando-id')).iziModal('open');
        });
        $(document).on('click', '.iziModal-content .close-modal', function (e) {
          e.preventDefault();
          $(this).parents('.rando-modal').iziModal('close');
        });
        $('.rando-modal').iziModal({
          history: true,
          loop: false,
          width: 800,
          // top: 30,
          // bottom: 30,
          navigateCaption: false,
          icon: 'fa fa-close',
          group: 'randos',
          transitionIn: 'comingIn',
          transitionOut: 'comingOut',
          transitionInOverlay: 'fadeIn',
          transitionOutOverlay: 'fadeOut'
        });
        var hasTimeout, fromGroupChange = false;
        $(document).on('closed', '.rando-modal', function (e) {
          var $modal = $(e.target);
          // console.log('closed', $modal.iziModal('getState'), fromGroupChange);
          hasTimeout = setTimeout(function(){
            if ( !fromGroupChange && $modal.iziModal('getState') != 'opening' && $modal.iziModal('getState') != 'opened' ) {
              // window.location.hash = '';
              if ( typeof history != 'undefined' && typeof history.pushState != 'undefined' ) {
                history.pushState(null,null,'#');
              }
              else {
                var scrollmem = $('html,body').scrollTop();
                window.location.hash = '';
                $('html,body').scrollTop(scrollmem);
              }
            }
          }, 500);
          $('html').removeClass('stop-scroll');
        });
        $(document).on('iziModal-group-change', function (e,modal){
          fromGroupChange = true;
          if ( hasTimeout ) { clearTimeout(hasTimeout); }
        });
        $(document).on('opened', '.rando-modal', function (e){
          fromGroupChange = false;
          if ( hasTimeout ) { clearTimeout(hasTimeout); }
        });
        $(document).on('opening', '.rando-modal', function (e){
          if ( hasTimeout ) { clearTimeout(hasTimeout); }
          var $modal = $(e.target),
              cid = 'rando-modal-' + $modal.data('rando-id'),
              cachedData = UTIL.cacheGet(cid);
          $modal.iziModal('startLoading');
          if ( false && cachedData ) {
            $modal.iziModal('setContent', cachedData);
            $modal.iziModal('stopLoading');
            UTIL.fire( 'common', 'randosNiveauxTooltip' );
          }
          else {
            // console.log(e);
            // console.log(modal);
            $.get(adminAjax.url,
              {
                'action': 'rando_modal',
                'rando_id': $modal.data('rando-id')
              }, function(data) {
                // console.log(data);
                UTIL.cacheSet(cid, data);
                $modal.iziModal('setContent', data);
                $modal.iziModal('stopLoading');
                UTIL.fire( 'common', 'randosNiveauxTooltip' );
            });
          }
          $('html').addClass('stop-scroll');
        });
      },
      agendaBlock: function() {
        const $agendas = $('.agenda-next');
        $agendas.each( function(){
          const $agenda = $(this),
                $controls = $( '.agenda-controls', $agenda );
          $controls.on('click', 'a', function (e) {
            e.preventDefault();
            const type    = $(this).hasClass('next') ? 'next' : 'prev',
                  newTime = $( '.agenda-block', $agenda ).data(type),
                  cid     = 'month-' + newTime,
                  cachedData = UTIL.cacheGet(cid);
            if ( cachedData ) {
              $( '.agenda-block', $agenda ).replaceWith( cachedData );
              UTIL.fire('common', 'agendaTooltip');
            }
            else {
              $agenda.addClass('loading');
              $.get(adminAjax.url,
                {
                  'action': 'agenda_month',
                  'date': newTime,
                }, function(data) {
                  UTIL.cacheSet(cid, data);
                  $( '.agenda-block', $agenda ).replaceWith( data );
                  $agenda.removeClass('loading');
                  UTIL.fire( 'common', 'agendaTooltip' );
              });
            }
          });
        });
        UTIL.fire( 'common', 'agendaTooltip' );
      },
      agendaTooltip: function() {
        $('.agenda-next .month-day').each(function(){
          if ( $('#' + $(this).attr('aria-describedby') ).length ) {
            new Tooltip( $(this).get()[0], {
              placement: "top",
              html: true,
              // delay: 300,
              title: $('#' + $(this).attr('aria-describedby') ).html(),
            });
          }
        });
      },
    },
    // Home page
    'home': {
      init: function() {

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Page liste des randos
    'page_template_page_randos': {
      init: function() {
        var offset = 150;
        var scrollTopRandos = UTIL.debounce(function() {
            var windowScrollTop = $(window).scrollTop(),
                windowHeight = $(window).height();
            $('.months .randos-month').each( function() {
              var thisOffset = $(this).offset().top;
              if ( windowScrollTop + windowHeight - thisOffset > offset && thisOffset > windowScrollTop ) {
                $(this).addClass('onscreen');
              }
              else {
                $(this).removeClass('onscreen');
              }
            });
          }, 100);
        scrollTopRandos();
        $(window).on('scroll', scrollTopRandos);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'historique': {
      init: function() {

        if ( typeof Chart != 'undefined' && typeof globalStats != 'undefined' ) {

          // Moyennes par année
          if ( $('#chart-months').length ) {
            var $chart = $('#chart-months'),
                $chartControl = $chart.parents('.chart-control');
            var currentYear = $chart.data('current'),
                minYear = $chart.data('min'),
                maxYear = $chart.data('max');
            var ctx = $chart.get(0).getContext('2d');
            var chartMonths = new Chart(ctx, {
                type: 'bar',
                data: {
                  labels: globalStats.months,
                  datasets: globalStats.bymonth[currentYear]
                },
                options: {
                  tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                      afterLabel: function(tooltipItem, data) {
                        return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] == 1 ? ' personne' : ' personnes';
                      }
                    }
                  },
                  responsive: true,
                  scales: {
                    xAxes: [{
                      stacked: true
                    }],
                    yAxes: [{
                      stacked: true,
                      ticks: {
                        beginAtZero:true
                      }
                    }]
                  }
                }
            });
            var updateChart = function( next ) {
              newYear = currentYear;
              if      ( !next && newYear - 1 >= minYear  )  { newYear--; }
              else if ( next && newYear + 1 <= maxYear )    { newYear++; }
              if ( newYear != currentYear && typeof globalStats.bymonth[newYear] != 'undefined' ) {
                chartMonths.data.datasets = globalStats.bymonth[newYear];
                currentYear = newYear;
                $('.current-year', $chartControl).html(currentYear);
                $('.next', $chartControl).removeClass('disabled');
                if ( currentYear == maxYear ) { $('.next', $chartControl).addClass('disabled'); }
                $('.prev', $chartControl).removeClass('disabled');
                if ( currentYear == minYear ) { $('.prev', $chartControl).addClass('disabled'); }
                chartMonths.update();
              }
            }
            $('.prev', $chartControl).on('click', function(){
              updateChart( false );
            });
            $('.next', $chartControl).on('click', function(){
              updateChart( true );
            });
          }

          // Fréquentation par niveau
          if ( $('#chart-niveaux').length ) {
            var $chart2 = $('#chart-niveaux'),
                $chart2Control = $chart2.parents('.chart-control'),
                total = globalStats.randosbylevel.vert + globalStats.randosbylevel.bleu + globalStats.randosbylevel.orange + globalStats.randosbylevel.noir;
                // total = globalStats.bylevel.vert + globalStats.bylevel.bleu + globalStats.bylevel.orange + globalStats.bylevel.noir;
            var ctx = $chart2.get(0).getContext('2d');
            var chartNiveaux = new Chart(ctx, {
              type: 'doughnut',
              data: {
                labels: [
                  'Niveau Vert', 'Niveau Bleu', 'Niveau Orange', 'Niveau Noir'
                ],
                datasets: [{
                  data: [ globalStats.randosbylevel.vert, globalStats.randosbylevel.bleu, globalStats.randosbylevel.orange, globalStats.randosbylevel.noir ],
                  // data: [ globalStats.bylevel.vert, globalStats.bylevel.bleu, globalStats.bylevel.orange, globalStats.bylevel.noir ],
                  backgroundColor: [
                    '#bbba4c',
                    '#1b9ec0',
                    '#edb244',
                    '#343a40',
                  ],
                }]
              },
              options: {
                tooltips: {
                  callbacks: {
                    // label: function(tooltipItem, data) {
                    //   return ( (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] / total * 100 ).toFixed(2) );
                    // },
                    afterLabel: function(tooltipItem, data) {
                      // return ' %';
                      return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] == 1 ? ' rando' : ' randos';
                    }
                  }
                }
              }
            });
          }

        } // end charts

        // Liste

        if ( typeof List != 'undefined' && $('#statistiques-list').length ) {
          var $statsList = $('#statistiques-list .list-wrapper'),
              $statsControls = $('#statistiques-list .controls');
          var options = {
            valueNames: [
              { data: ['type','timestamp','year','niveau1','niveau2'] },
              'date',
              'aff1',
              'aff2',
              'dist1',
              'dist2',
              'note'
            ]
          };

          var currentYear = $('select.year', $statsControls).val();

          var statsListObj = new List($statsList.get(0), options);
          var filterList = function(filter) {
            if ( filter == 'all' ) {
              statsListObj.filter(function(item){
                return item.values().year == currentYear;
              });
            }
            else {
              var filterMulti = filter.split('=');
              statsListObj.filter(function(item) {
                var inCurrent = item.values().year == currentYear;
                return inCurrent && ( filterMulti[0] == 'type' ? item.values()[filterMulti[0]] == filterMulti[1] : (item.values()[filterMulti[0]+'1'] == filterMulti[1] || item.values()[filterMulti[0]+'2'] == filterMulti[1]) );
              });
            }
            statsListObj.sort('timestamp', { order: "desc" });
          };
          filterList( 'all' );
          // on clik
          $('.button', $statsControls).on('click', function(){
            $('.button', $statsControls).not($(this)).removeClass('selected');
            $(this).addClass('selected');
            filterList( $(this).data('filter') );
          });
          // year selection
          $('select.year', $statsControls).on('change', function(){
            if ( $(this).val() != currentYear ) {
              currentYear = $(this).val();
              filterList( $('.button.selected', $statsControls).length ? $('.button.selected', $statsControls).data('filter') : 'all' );
            }
          });

          // init header
          $('.header', $statsList).removeClass('hidden');

        }

        // end list

      }
    },
    'category_galerie_photo': {
      init: function() {
        UTIL.fire('single_post', 'init');
      }
    },
    'single_post': {
      init: function() {

        // Randos side
        $('.owl-carousel.gallery').owlCarousel({
          loop: true,
          margin: 10,
          navText: [ '<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>' ],
          responsiveClass: true,
          nav: true,
          dots: true,
          items: 1,
        });

        var initPhotoSwipeFromDOM = function(gallerySelector) {

            // parse slide data (url, title, size ...) from DOM elements
            // (children of gallerySelector)
            var parseThumbnailElements = function(el) {
                var thumbElements = el.childNodes,
                    numNodes = thumbElements.length,
                    items = [],
                    figureEl,
                    linkEl,
                    size,
                    item;

                for(var i = 0; i < numNodes; i++) {

                    figureEl = thumbElements[i]; // <figure> element

                    // include only element nodes
                    if(figureEl.nodeType !== 1) {
                        continue;
                    }

                    linkEl = figureEl.children[0]; // <a> element

                    size = linkEl.getAttribute('data-size').split('x');

                    // create slide object
                    item = {
                        src: linkEl.getAttribute('href'),
                        w: parseInt(size[0], 10),
                        h: parseInt(size[1], 10)
                    };



                    if(figureEl.children.length > 1) {
                        // <figcaption> content
                        item.title = figureEl.children[1].innerHTML;
                    }

                    if(linkEl.children.length > 0) {
                        // <img> thumbnail element, retrieving thumbnail url
                        item.msrc = linkEl.children[0].getAttribute('src');
                    }

                    item.el = figureEl; // save link to element for getThumbBoundsFn
                    items.push(item);
                }

                return items;
            };

            // find nearest parent element
            var closest = function closest(el, fn) {
                return el && ( fn(el) ? el : closest(el.parentNode, fn) );
            };

            // triggers when user clicks on thumbnail
            var onThumbnailsClick = function(e) {
                e = e || window.event;
                e.preventDefault ? e.preventDefault() : e.returnValue = false;

                var eTarget = e.target || e.srcElement;

                // find root element of slide
                var clickedListItem = closest(eTarget, function(el) {
                    return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
                });

                if(!clickedListItem) {
                    return;
                }

                // find index of clicked item by looping through all child nodes
                // alternatively, you may define index via data- attribute
                var clickedGallery = $(clickedListItem).parents('.owl-item').get()[0],
                    childNodes = $(clickedListItem).parents('.owl-item').get()[0].childNodes,
                    numChildNodes = childNodes.length,
                    nodeIndex = 0,
                    index;

                for (var i = 0; i < numChildNodes; i++) {
                    if(childNodes[i].nodeType !== 1) {
                        continue;
                    }

                    if(childNodes[i] === clickedListItem) {
                        index = nodeIndex;
                        break;
                    }
                    nodeIndex++;
                }



                if(index >= 0) {
                    // open PhotoSwipe if valid index found
                    openPhotoSwipe( index, clickedGallery );
                }
                return false;
            };

            // parse picture index and gallery index from URL (#&pid=1&gid=2)
            var photoswipeParseHash = function() {
                var hash = window.location.hash.substring(1),
                params = {};

                if(hash.length < 5) {
                    return params;
                }

                var vars = hash.split('&');
                for (var i = 0; i < vars.length; i++) {
                    if(!vars[i]) {
                        continue;
                    }
                    var pair = vars[i].split('=');
                    if(pair.length < 2) {
                        continue;
                    }
                    params[pair[0]] = pair[1];
                }

                if(params.gid) {
                    params.gid = parseInt(params.gid, 10);
                }

                return params;
            };

            var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
                var pswpElement = document.querySelectorAll('.pswp')[0],
                    gallery,
                    options,
                    items;

                items = parseThumbnailElements(galleryElement);

                // define options (if needed)
                options = {

                    // define gallery index (for URL)
                    galleryUID: galleryElement.getAttribute('data-pswp-uid'),

                    getThumbBoundsFn: function(index) {
                        // See Options -> getThumbBoundsFn section of documentation for more info
                        var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                            pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                            rect = thumbnail.getBoundingClientRect();

                        return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
                    }

                };

                // PhotoSwipe opened from URL
                if(fromURL) {
                    if(options.galleryPIDs) {
                        // parse real index when custom PIDs are used
                        // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                        for(var j = 0; j < items.length; j++) {
                            if(items[j].pid == index) {
                                options.index = j;
                                break;
                            }
                        }
                    } else {
                        // in URL indexes start from 1
                        options.index = parseInt(index, 10) - 1;
                    }
                } else {
                    options.index = parseInt(index, 10);
                }

                // exit if index not found
                if( isNaN(options.index) ) {
                    return;
                }

                if(disableAnimation) {
                    options.showAnimationDuration = 0;
                }

                // Pass data to PhotoSwipe and initialize it
                gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
                gallery.init();
            };

            // loop through all gallery elements and bind events
            var galleryElements = document.querySelectorAll( gallerySelector );

            for(var i = 0, l = galleryElements.length; i < l; i++) {
                galleryElements[i].setAttribute('data-pswp-uid', i+1);
                galleryElements[i].onclick = onThumbnailsClick;
            }

            // Parse URL and open gallery if it contains #&pid=3&gid=1
            var hashData = photoswipeParseHash();
            if(hashData.pid && hashData.gid) {
                openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
            }
        };

        // execute above function
        initPhotoSwipeFromDOM('.owl-carousel.gallery');

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
    debounce: function(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this, args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) { func.apply(context, args); }
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) { func.apply(context, args); }
      };
    },
    cacheGet: function( cid ) {
      if ( typeof lscache == 'undefined' || !lscache.supported()) {
        return false;
      }
      return lscache.get(cid);
    },
    cacheSet: function( cid, data ) {
      if ( typeof lscache == 'undefined' || !lscache.supported()) {
        return false;
      }
      return lscache.set(cid, data, 24 * 60);
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
